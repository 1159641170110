
/* pdf styles */
.modal-main {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	width: 100vw;
	height: 100vh;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	display: flex;
	justify-content: center;
	flex-direction: column;
	background-color: rgba(0,0,0,.8); /* if this changes, all template navigation buttons must also be updated!!!! */
	z-index: 1000;
}

/* iframe styles */
.modal-iframe {
	position: absolute; /* specifically for opening from listings */
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,.8); /* if this changes, all template navigation buttons must also be updated!!!! */
	padding: 5em 18em;
	z-index: 100; /* for opening from listings */
	transition: .5s padding;
}

.adaActive .modal-iframe {
	padding: 24em 18em 5em 18em
}

.hideMask.modal-iframe {
	padding: 0;
}

.hideMask {
	padding: 0;
	background: none;
}

.modalButton{
	margin: 3px
}

.navBtn {
	display: flex;
	align-items: flex-end;
	width: 12em;
	height: 3em;
	font-size: 1em;
	text-transform: uppercase;
	color: white;
	text-shadow: 0px 0px 3px hsl(211deg 100% 9% / 64%);
	background: var(--btnBgColor);
	border: var(--btnBorder);
	border-radius: var(--btnBorderRadius);
	cursor: pointer;
	pointer-events: all;
	z-index: 9999;
}

.buttonHolder {
	text-align: center;
	top:90%;
	left:50%;
	transform: translate(-50%,-50%);
}
  
.display-block {
	display: block;
}

.display-none {
	display: none;
}

.react-pdf__Page {
	margin-top: 10px;
}

.react-pdf__Page__canvas {
	margin: 0 auto;
}
.annotationLayer {
	display: none;
}

@media screen and (orientation:portrait) {
	.modal-iframe {
		padding: 18em 5em;
	} 
	.adaActive .modal-iframe {
		padding: 58em 5em 5em 5em;
	}
	.adaActive .hideMask.modal-iframe {
		padding: 0;
	}
}