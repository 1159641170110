.new-brood-slide {
  position: absolute;
  opacity: 0;
}

.new-brood-slide-transition {
  transition: opacity 1s ease-in-out;
}

.new-brood-slide-curr {
  opacity: 1;
  z-index: 2;
}

.new-brood-slide-next {
  opacity: 0;
  z-index: 1;
}

/* single slide is curr AND next */
.new-brood-slide-curr.new-brood-slide-next {
  opacity: 1;
  z-index: 2;
}