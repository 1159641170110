/* Open Sans 300 */
@font-face {
    font-family: 'OpenSans300';
    font-style: normal;
    font-weight: 300;
    src:
        url('https://s3.amazonaws.com/ts-condor-assets/fonts/open-sans-v17-latin-300.woff2') format('woff2'),
        url('https://s3.amazonaws.com/ts-condor-assets/fonts/open-sans-v17-latin-300.woff') format('woff')
  }

  /* Open Sans 400 */
  @font-face {
    font-family: 'OpenSans400';
    font-style: normal;
    font-weight: 400;
    src:
        url('https://s3.amazonaws.com/ts-condor-assets/fonts/open-sans-v17-latin-regular.woff2') format('woff2'),
        url('https://s3.amazonaws.com/ts-condor-assets/fonts/open-sans-v17-latin-regular.woff') format('woff')
  }

  /* Open Sans 300 Italic */
  @font-face {
    font-family: 'OpenSans300Italic';
    font-style: italic;
    font-weight: 300;
    src:
        url('https://s3.amazonaws.com/ts-condor-assets/fonts/open-sans-v17-latin-300italic.woff2') format('woff2'),
        url('https://s3.amazonaws.com/ts-condor-assets/fonts/open-sans-v17-latin-300italic.woff') format('woff')
  }

  /* Open Sans 400 Italic */
  @font-face {
    font-family: 'OpenSans400';
    font-style: italic;
    font-weight: 400;
    src:
        url('https://s3.amazonaws.com/ts-condor-assets/fonts/open-sans-v17-latin-italic.woff2') format('woff2'),
        url('https://s3.amazonaws.com/ts-condor-assets/fonts/open-sans-v17-latin-italic.woff') format('woff')
  }

  /* Open Sans 600 Italic */
  @font-face {
    font-family: 'OpenSans600Italic';
    font-style: italic;
    font-weight: 600;
    src:
        url('https://s3.amazonaws.com/ts-condor-assets/fonts/open-sans-v17-latin-600italic.woff2') format('woff2'),
        url('https://s3.amazonaws.com/ts-condor-assets/fonts/open-sans-v17-latin-600italic.woff') format('woff')
  }

  /* Open Sans 600 */
  @font-face {
    font-family: 'OpenSans600';
    font-style: normal;
    font-weight: 600;
    src:
        url('https://s3.amazonaws.com/ts-condor-assets/fonts/open-sans-v17-latin-600.woff2') format('woff2'),
        url('https://s3.amazonaws.com/ts-condor-assets/fonts/open-sans-v17-latin-600.woff') format('woff')
  }

  /* Open Sans 700 */
  @font-face {
    font-family: 'OpenSans700';
    font-style: normal;
    font-weight: 700;
    src:
        url('https://s3.amazonaws.com/ts-condor-assets/fonts/open-sans-v17-latin-700.woff2') format('woff2'),
        url('https://s3.amazonaws.com/ts-condor-assets/fonts/open-sans-v17-latin-700.woff') format('woff')
  }

  /* Open Sans 800 */
  @font-face {
    font-family: 'OpenSans800';
    font-style: normal;
    font-weight: 800;
    src:
        url('https://s3.amazonaws.com/ts-condor-assets/fonts/open-sans-v17-latin-800.woff2') format('woff2'),
        url('https://s3.amazonaws.com/ts-condor-assets/fonts/open-sans-v17-latin-800.woff') format('woff')
  }

  /* Open Sans 700 Italic */
  @font-face {
    font-family: 'OpenSans700Italic';
    font-style: italic;
    font-weight: 700;
    src:
        url('https://s3.amazonaws.com/ts-condor-assets/fonts/open-sans-v17-latin-700italic.woff2') format('woff2'),
        url('https://s3.amazonaws.com/ts-condor-assets/fonts/open-sans-v17-latin-700italic.woff') format('woff')
  }

  /* Open Sans 800 Italic */
  @font-face {
    font-family: 'OpenSans800Italic';
    font-style: italic;
    font-weight: 800;
    src:
        url('https://s3.amazonaws.com/ts-condor-assets/fonts/open-sans-v17-latin-800italic.woff2') format('woff2'),
        url('https://s3.amazonaws.com/ts-condor-assets/fonts/open-sans-v17-latin-800italic.woff') format('woff')
  }