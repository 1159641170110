
/* ========== Reset ========== */
* {
	box-sizing: border-box;
}

html,
body,
p,
div,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
dl,
img,
pre,
form,
fieldset,
button {
	margin: 0;
	padding: 0;
}
img,
fieldset {
	border: 0;
}


body,
html,
#root,
.App {
	height: 100%;
	width: 100%;
}

body {
	background-color: #fff;
	font-style: normal;
	font-weight: 400;
	line-height: 1.2;
	-ms-overflow-style: -ms-autohiding-scrollbar;
}

/* Default margins */
p,
ul,
ol,
dl,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
pre,
form,
table {
	margin: .85em 0 0 0;
}

/* Links */
a,
a:hover,
a:active,
a:focus,
a:active {
	color: var(--textColorPrimary);
	text-decoration: none;
}


/* Headings */
h1 {
	font-size: 2.07em;
	font-style: inherit;
	line-height: 1.1;
	color: #172B4D;
	font-weight: 600;
	letter-spacing: -0.01em;
	margin-top: 40px;
}
h2 {
	font-size: 1.7em;
	font-style: inherit;
	line-height: 1.16;
	color: #172B4D;
	font-weight: 500;
	letter-spacing: -0.01em;
	margin-top: 40px;
}
h3 {
	font-size: 1.42em;
	font-style: inherit;
	line-height: 1.2;
	color: #172B4D;
	font-weight: 500;
	letter-spacing: -0.008em;
	margin-top: 28px;
}
h4 {
	font-size: 1.14em;
	font-style: inherit;
	line-height: 1.25;
	color: #172B4D;
	font-weight: 600;
	letter-spacing: -0.006em;
	margin-top: 24px;
}
h5 {
	font-size: 1em;
	font-style: inherit;
	line-height: 1.14;
	color: #172B4D;
	font-weight: 600;
	letter-spacing: -0.003em;
	margin-top: 16px;
}
h6 {
	font-size: 0.85;
	font-style: inherit;
	line-height: 1.3;
	color: #172B4D;
	font-weight: 600;
	margin-top: 20px;
	text-transform: uppercase;
}

/* Lists */
ul,
ol,
dl {
	padding-left: 40px;
}
[dir='rtl']ul,
[dir='rtl']ol,
[dir='rtl']dl {
	padding-left: 0;
	padding-right: 40px;
}

dd,
dd + dt,
li + li {
	margin-top: 4px;
}
ul ul:not(:first-child),
ol ul:not(:first-child),
ul ol:not(:first-child),
ol ol:not(:first-child) {
	margin-top: 4px;
}

/* remove top margin for first element */
p:first-child,
ul:first-child,
ol:first-child,
dl:first-child,
h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child,
blockquote:first-child,
pre:first-child,
form:first-child,
table:first-child {
	margin-top: 0;
}

/* Quotes */
blockquote,
q {
	color: inherit;
}
blockquote {
	border: none;
	padding-left: 40px;
}
[dir='rtl'] blockquote {
	padding-left: 0;
	padding-right: 40px;
}

blockquote::before,
q::before {
	content: '\201C';
}

blockquote::after,
q::after {
	content: '\201D';
}

blockquote::before {
	float: left;
	/* to keep the quotes left of any child elements like blockquote > p */
	margin-left: -1em;
	text-align: right;
	width: 1em;
}
[dir='rtl'] blockquote::before {
	float: right;
	margin-right: -1em;
	text-align: left;
}

blockquote > :last-child {
	display: inline-block; /* so the quotes added via pseudos follow it immediately. */
}

/* Other typographical elements */

table {
	border-collapse: collapse;
	width: 100%;
}

td,
th {
	border: none;
	padding: 4px 8px;
	text-align: left;
}

th {
	vertical-align: top;
}

td:first-child,
th:first-child {
	padding-left: 0;
}

td:last-child,
th:last-child {
	padding-right: 0;
}


/* IE11 doesn't support <template> elements which shouldn't be displayed */
template {
	display: none;
}

/* IE11 and some older browsers don't support these elements yet and treat them as display: inline; */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
	display: block;
}

strong {
	font-family: var(--fontBold);
}

/* Suppress the ugly broken image styling in Firefox */
@-moz-document url-prefix() {
	img {
		font-size: 0;
	}
	img:-moz-broken {
		font-size: inherit;
	}
}

