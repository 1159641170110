.brood-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
	background-color: transparent;
}

.brood-group {
  height: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.brood-slide-parent {
  height: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.brood-slide {
  max-height: 100%;
  max-width: 100%;
  display: flex;
}

.hidden {
  display: none;
}
