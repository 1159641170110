.loading-screen {
	position: fixed;
	inset: 0;
	background: linear-gradient(50deg, #453761, #284b78);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	z-index: 999999; 
    pointer-events: none; 
    opacity: 1;
    transition: opacity .5s ease; 
}
.loading-logo {
	width: 32rem;
	height: auto;
	margin-bottom: 2rem;
	animation: pulse 2s infinite 0.5s;
}

@keyframes pulse {
  0%, 100% {
	opacity: 1;
	transform: scale(1);
  }
  50% {
	opacity: 0.7;
	transform: scale(0.95);
  }
}

.loader-text {
	font-size: 2rem;
	margin-bottom: 1rem;
}

.loader-message {
	font-size: 1.5rem;
	color: #fff;
	font-family: sans-serif;
	animation: fadeInOut 3s infinite ease-in-out;
	transform-origin: center;
	display: inline-block; /* Ensures smooth animation */
}


@keyframes fadeInOut {
	0% {
		opacity: 0;
		transform: translateY(.8rem);
	}
	50% {
		opacity: 1;
		transform: translateY(0);
	}
	100% {
		opacity: 0;
		transform: translateY(-.8rem);
	}
}

@media (min-width: 2160px) {
	.loading-logo {
		width: 48rem;
	}
	.loader-text {
		font-size: 3rem;
	}
	.loader-message {
		font-size: 2rem;
	}
}