.sb-show-main {
	background-color: #444444;
}

.sb-show-main a {
	color: #1EA7FD;
}

.sb-intro {
	max-width: 600px;
	padding: 30px;
}

.sb-intro h1 {
	color: #fff;
}

.sb-show-main .clock_timeCont {
	justify-content: flex-start;
}

.refreshButton {
	position: absolute;
    top: 0;
    right: 0;
	color: #FFFFFF;
    background: #333;
    font-family: "Nunito Sans",-apple-system,".SFNSText-Regular","San Francisco",BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Helvetica,Arial,sans-serif;
	font-weight: 700;
	padding: 10px;
	border-top: 0;
	border-right: 0;
    border-bottom: 1px solid rgba(255,255,255,.1);
    border-left: 1px solid rgba(255,255,255,.1);
    margin-left: -1px;
	border-radius: 0 0 0 4px;
	cursor: pointer;
	outline: 0;
}